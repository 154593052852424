import React from "react";
import Layout from "@/layout";
import SeoComponent from "@/modules/seo";
import Hero from "@/modules/hero";
import Intro from "@/modules/intro";
import Offer from "@/modules/offer"
import Video from "@/modules/video";
import Showcase from "@/modules/showcase";
import Process from "@/modules/process";

type IndexType = {
  pageContext: any;
};

const Index: React.FC<IndexType> = ({ pageContext }) => {
  const {
    title,
    description,
    keywords,
    accentColour,
    hero,
    intro,
    offer,
    video,
    showcase,
    footer,
  } = pageContext;

  return (
<React.StrictMode>
  <Layout
    colour={accentColour}
    footerL1={footer.lineOne}
    footerL2={footer.lineTwo}
    data-scroll-section
  >
      <SeoComponent
        title={title}
        description={description}
        keywords={keywords}
      />
      <Hero
        lineOne={hero.lineOne}
        lineTwo={hero.lineTwo}
        lineThree={hero.lineThree}
        cta={hero.cta}
      />
      <Intro
        col1Header={intro.col1Header}
        col1Paragraph1={intro.col1Paragraph1}
        col1Paragraph2={intro.col1Paragraph2}
        col2Header={intro.col2Header}
        col2Paragraph1={intro.col2Paragraph1}
        col2Paragraph2={intro.col2Paragraph2}
      />
      <Video
        videoItems={video}
        title={video.title}
        link={video.link}
        videoUrl={video.videoUrl}
      />
      <Offer title={offer.title} />
      <Showcase showcaseItems={showcase.items} />
      <Process title="Our process" />
    </Layout>
</React.StrictMode>

);
};

export default Index;
