import React, { useEffect, useState } from "react";
import RightArrow from "@/img/right-arrow.svg";

const ContactForm: React.FC = () => {
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // Update error/success state
  useEffect(() => {
    if (success) {
      setError(false);
    } else if (error) {
      setSuccess(false);
    }
  }, [error, success]);

  // Form Submit - Connects to api/form-submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      fields: [
        {
          name: "fullName",
          value: fullName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
      ],
    };
    setLoading(true);
    const response = await fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/7564241/a9947622-6b4a-43b8-9f13-bf84f9251179",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status === 200) {
      setSuccess(true);
    } else {
      setError(true);
    }
    setLoading(false);
  };
  return (
    <form className="c-contact-form" onSubmit={handleSubmit}>
      <div className="form-row -split">
        <input
          placeholder="Name *"
          type="text"
          name="fullName"
          required
          onChange={e => setFullName(e.target.value)}
        />
        <input
          placeholder="Email *"
          type="email"
          name="email"
          required
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="form-row -whole">
        <input
          placeholder="Mobile"
          name="phone"
          type="tel"
          onChange={e => setPhone(e.target.value)}
        />
      </div>
      <div className="form-row -checkbox">
        <input type="checkbox" required name="privacy" />
        <label htmlFor="privacy">
          By using this form you agree with the storage and handling of your
          data by this website.{" "}<br/>
          <a
            className="form-link underline -hover-underline"
            href="https://thebeinggroup.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </label>
      </div>
      <div className="c-contact-form__submit form-submit underline -hover-underline">
        <input type="submit"></input>
        <RightArrow className="submit-icon" />
      </div>
      {loading && <p className="body-regular">Submitting...</p>}
      {error && !loading && (
        <p className="body-regular -error">
          Oops — something went wrong. Please try again.
        </p>
      )}
      {success && !loading && (
        <p className="body-regular">
          We've received your details! Be in touch soon.
        </p>
      )}
    </form>
  );
};

export default ContactForm;
