import React, { ReactNode, useRef, CSSProperties } from "react";
import "@/styles/main.css";
import Nav from "@/modules/nav";
import Footer from "@/modules/footer";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";

type LayoutProps = {
  children: ReactNode;
  colour?: string;
  footerL1: string;
  footerL2: string;
};

const Layout: React.FC<LayoutProps> = ({
  children, colour = "#fff", footerL1, footerL2
}) => {
  const containerRef = useRef(null);
  return (
    <LocomotiveScrollProvider
      className="layout"
      containerRef={containerRef}
      options={{
        smooth: true,
        lerp: 0.2,
      }}
      watch={[]}
    >
      <div
        data-scroll-container
        data-scroll-section
        ref={containerRef}
        style={{ "--accent": colour } as CSSProperties}
      >
        <Nav />
        <main>{children}</main>
        <Footer
          footerL1="Ready to start an"
          footerL2="Ready to start an e&#x2011;learning project?"
        />
      </div>
    </LocomotiveScrollProvider>
  );
};

export default Layout;
