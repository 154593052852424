import React, {useEffect, useState} from "react";
import ReactPlayer from 'react-player';

import  {VideoItemType} from "@/components/video-item";
import {useInView} from "react-intersection-observer";

type ShowcaseType = {
  videoItems: VideoItemType;
  title: string;
  link: string;
  videoUrl: string;
};
const Video: React.FC<ShowcaseType> = ({
  title,
  link,
  videoUrl}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    if (inView) {
      setIsLoaded(true);
    }
  }, [inView]);

  return (
    <section
      className=" single-vid-padding"
      data-scroll-section
      id="video-intro-marker"
      ref={ref}
    >
      <script src="https://player.vimeo.com/api/player.js"></script>
      <div className="c-showcase-item__wrapper">
        <div className="c-showcase-item container single-vid__wrapper">
          <div className=" c-single-vid">
            <div className="c-showcase-item__media" id="single-vid">
              {/*<div className="vimeo-overlay"></div>*/}
              {/*812303901*/}
              <div className={"vimeo-single-vid"}>
                <ReactPlayer
                  url={videoUrl}
                  loop={true}
                  className={"iframe-single-vid"}
                  playing={isLoaded}
                  controls={true}
                  playsinline={true}
                  muted={false}
                  volume={0.8}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Video;
