import React from "react";
import imageCol1 from '../static/process/Learning-Process_0001.jpg';
import imageCol2 from '../static/process/Learning-Process_0002.jpg';
import imageCol3 from '../static/process/Learning-Process_0003.jpg';
import imageCol4 from '../static/process/Learning-Process_0004.jpg';
import imageCol5 from '../static/process/Learning-Process_0005.jpg';

type ProcessType = {
  title: string;
};
const Process: React.FC<ProcessType> = () => {
  return (
    <section
      className="c-process__wrapper light"
      data-scroll-section
      id="process-marker"
    >
      <div
        className="c-process container"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#process-marker"
      >
        <div
          className="row"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#process-marker"
        >
          <div className="col-12 col-lg-9 mb-5">
            <h4 className="mb-4"><strong>Our process</strong></h4>
            <p>We are experts in e-learning. We have a full-time, co-located team of specialists and creatives that work together to create outstanding e-learning modules and programs. We follow a highly creative and innovative process that is structured to create the best possible content.</p>
          </div>
        </div>

        <div
          className="row"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#process-marker"
        >
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <p><img src={imageCol1}/></p>
            <h5 className="mb-3"><strong>Start with strategy</strong></h5>
            <p>We approach every e-learning challenge strategically. What are we trying to achieve? Why are we doing
              this? What would change if the e-learning is effective? How does it fit in with other initiatives?</p>
            <p>During this early stage we talk to as many people as possible, asking the right questions to truly
              understand the objectives.</p>
            <p>An education specialist leads this stage, and continues as project lead throughout the subsequent phases
              to ensure the final product achieves the original intent.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <p><img src={imageCol2}/></p>
            <h5 className="mb-3"><strong>Educational and behavioural insights</strong></h5>
            <p>Our specialists will explore the best ways to optimise learning and behaviour change. We view the topic from the perspective of the learner, and design a sound framework for the e-learning module or program. We often co-design this framework with subject matter experts and members of the target learner group.</p>
            <p>By the end of this stage we have a framework that will form the basis for the creative process.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <p><img src={imageCol3}/></p>
            <h5 className="mb-3"><strong>Design</strong></h5>
            <p>This process involves a multi-disciplinary team picked specifically for the project. The education specialist leads the team, which usually includes a graphic designer, visual media expert and copywriter, all working closely with the client and subject matter experts.</p>
            <p>A storyboard is created, followed by a script and visual design style. This is where icons, infographics, font, colour palette, illustrations, animation style, video style and music are finalised.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <p><img src={imageCol4}/></p>
            <h5 className="mb-3"><strong>Producing content</strong></h5>
            <p>Our team of in-house creatives produce video content, voice-overs, animation, text copy – all the elements that make up the actual e-learning module. Sometimes we use video and other content provided by the client. Our in-house producer coordinates pre-production and production, and our editors finalise all elements ready for inclusion in the final module.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <p><img src={imageCol5}/></p>
            <h5 className="mb-3"><strong>Creating the actual module</strong></h5>
            <p>Our technical e-learning expert will use an appropriate authoring tool – compatible with the client’s LMS or systems – to bring together all the elements. We test, refine and finalise. We then support the client for a period post-launch to ensure the module is stable inside their system.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <div className="process-box p-5">
              <h3 className="mb-3">With our Strategy, Learning, Communication, Design and Web offering under one roof – your e-learning
              solution is in the right hands with BEING.</h3>
              <p>Read our Learning and Development specialist’s thoughts on e-learning.</p>
              <p><a href="https://thebeinggroup.com/blog/e-learning-getting-it-right/" target="_blank">Read article.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
