import React from "react";

type OfferType = {
  title: string;
};
const Offer: React.FC<OfferType> = () => {
  return (
    <section
      className="c-offer__wrapper light"
      data-scroll-section
      id="offer-marker"
    >
      <div
        className="c-offer"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#offer-marker"
      >
        <div
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#offer-marker"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9">
                <h3 className="c-offer-list__heading mb-4"><strong>Our e-learning solutions are created by specialists, developed
                  end-to-end in-house, and have proven, transformative impact.</strong></h3>
                <h4 className="mb-4">We offer:</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <ul className="m-0">
                  <li>
                    Bespoke e-learning course development
                  </li>
                  <li>
                    Learning design
                  </li>
                  <li>
                    Technical e-learning module development
                  </li>
                  <li>
                    Custom Learning Management Systems development
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ul className="m-0">
                  <li>
                    Module design within existing Learning Management Systems
                  </li>
                  <li>
                    Educational video production for use within modules
                  </li>
                  <li>
                    Module graphic design
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ul className="m-0">
                  <li>
                    Course delivery and training
                  </li>
                  <li>
                    Leadership and development programs
                  </li>
                  <li>
                    Training programs (including virtual)
                  </li>
                  <li>
                    Supporting learning materials development
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offer;
