import React from "react";

type IntroType = {
  col1Header: string;
  col1Paragraph1: string;
  col1Paragraph2: string;
  col2Header: string;
  col2Paragraph1: string;
  col2Paragraph2: string;
};
const Intro: React.FC<IntroType> = ({
  col1Header, col1Paragraph1, col1Paragraph2, col2Header, col2Paragraph1, col2Paragraph2
}) => {
  return (
    <section
      className="c-intro__wrapper light"
      data-scroll-section
      id="intro-marker"
    >
      <div
        className="c-intro"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#intro-marker"
      >
        <div
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#intro-marker"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-9">
                <h3 className="c-intro-list__heading heading-intro"><strong>You’ve come to the right place.</strong></h3>
                <p>We’re The Being Group – a strategy and creative services company offering end-to-end e-learning development.</p>
                <p className="pad-top">We’re an integrated group of learning, communication and web development specialists and creatives. <strong>Our
                  specialist e-learning services are shaped by behavioural science, behavioural economics, neuroscience,
                  learning theory, strategic design and inspirational creativity.</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
