import React from "react";
import ShowcaseItem, { ShowcaseItemType } from "@/components/showcase-item";

type ShowcaseType = {
  showcaseItems: ShowcaseItemType[];
};

const Showcase: React.FC<ShowcaseType> = ({ showcaseItems }) => {
  return (
    <section
      className="m-showcase__container dark "
      data-scroll-section
      id="showcase-section-marker"
    >
      <div
        className="container"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#showcase-section-marker"
      >
        <div
          className="m-showcase__heading"
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#showcase-section-marker"
        >
          <h2 className="heading-large">Showcase</h2>
        </div>
        <div
          data-scroll
          data-scroll-speed="1.1"
          data-scroll-target="#showcase-section-marker"
        >
          {showcaseItems.map((item, i) => (
            <ShowcaseItem
              videoTitle={item.videoTitle}
              title={item.title}
              description={item.description}
              outcome={item.outcome}
              image={item.image}
              link={item.link}
              video={item.video}
              imageLength={item.imageLength}
              key={i}
              index={i}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Showcase;
