import React from "react";
import ContactDetailsFooter from "@/elements/contact-details-footer";
import HeroLine from "@/img/hero-line.svg";
import ContactForm from "@/components/contact-form";
import Facebook from "@/img/facebook.svg";
import Instagram from "@/img/instagram.svg";
import LinkedIn from "@/img/linkedin.svg";

type FooterType = {
  footerL1: string;
  footerL2: string;
};

const Footer: React.FC<FooterType> = ({
  footerL1, footerL2
}) => {
  const year = new Date().getFullYear();
  return (
    <footer
      data-scroll-section
      className="m-footer__wrapper dark"
      id="footer-marker"
    >
      <div className="m-footer container">
        <div className="m-footer__inner">
          <h2 className="m-footer__heading heading-large">
            {footerL2}
          </h2>
          <div
            className="m-footer__contact underline -underline-top"
            data-scroll
            data-scroll-target="#footer-marker"
          >
            <ContactDetailsFooter />
          </div>
        </div>
        <div className="m-footer__bottom">
          <p className="m-footer__copyright legal">
            {`© Being Group International ${year}. All rights reserved. `}
            <a
              href="https://thebeinggroup.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline -hover-underline"
            >
              Privacy Policy.
            </a>
          </p>
          <div className="m-footer__social-wrapper">
            <a
              href="https://www.facebook.com/thebeinggroup"
              target="_blank"
              aria-label="Facebook"
              rel="noopener noreferrer"
              className="m-footer__social-icon"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/thebeinggroup/"
              target="_blank"
              aria-label="Twitter"
              rel="noopener noreferrer"
              className="m-footer__social-icon"
            >
              <Instagram />
            </a>
            <a
              href="https://www.linkedin.com/company/the-being-group"
              target="_blank"
              aria-label="LinkedIn"
              rel="noopener noreferrer"
              className="m-footer__social-icon"
            >
              <LinkedIn />
            </a>
          </div>
        </div>
        <div
          className="m-footer__form-wrapper light footer-form"
          data-scroll
          data-scroll-target="#footer-form-marker"
          data-scroll-repeat
        >
          <h3 className="heading-form">Get in touch.</h3>
          <ContactForm />
        </div>
        <div className="c-footer__form-marker" id="footer-form-marker"></div>
      </div>
      <HeroLine
        className="c-footer__line"
        data-scroll
        data-scroll-target="#footer-marker"
        data-scroll-offset="55%"
      />
    </footer>
  );
};

export default Footer;
